<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="项目批次">
            <a-input
              v-decorator="['item_no', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入项目批次"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="套餐">
            <a-select
              v-decorator="['agents_product_id']"
              :loading="loadingProductionOptions"
              @focus="fetchProductionOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in productionOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="账单时间">
            <a-range-picker
              style="width: 100%;"
              v-decorator="['created_at']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findCarrierTypeOptions } from '@/api/common_options'
import { findAgentsProductsOptions } from '@/api/agents_product'
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchAgentMonthlyBill',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'agent_purchase_bill_search' }),
      carrierTypeOptions: findCarrierTypeOptions(),
      loadingProductionOptions: false,
      productionOptions: []
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    // 加载套餐选项
    fetchProductionOptions() {
      if (this.productionOptions.length === 0) {
        this.loadingProductionOptions = true
        findAgentsProductsOptions({ agent_id: this.agentId }).then(res => {
          if (res.code === 0) {
            this.productionOptions = res.data
          }
          this.loadingProductionOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
